<template>
  <div class="box-started">
    <div id="detail_news" class="container">
      <div class="columns set-flex mt-5">
        <div class="column is-full pt-0" v-if="errorBlogId">
          <div class="show-this-page">
            <span>
              <a href="/">Home</a>
            </span>
            <span class="space">></span>
            <a
              v-if="$route.params.category != ''"
              :href="`/category/${$route.params.category}`"
              style="text-transform: uppercase"
            >
              {{ $route.params.category }}</a
            >
          </div>
        </div>
        <div class="column is-two-thirds pt-0" v-if="errorBlogId">
          <div style="position: relative">
            <b-loading :active="true" v-model="loading" :is-full-page="false">
            </b-loading>
            <div  v-if="loading == false">
              <div class="info-detail">
                <h1 class="entry-title">{{ dataById.title }}</h1>
              </div>
              <div class="inside-detail">
                <div
                  class="box-img"
                  style="cursor: pointer"
                  @click="isImageModalActive = true"
                >
                  <b-image
                    :src="getGhostImage(dataById.feature_image, 1000)"
                    :srcset="
                      getGhostImageSet(dataById.feature_image, [300, 600, 1000])
                    "
                    ratio="16by9"
                    :alt="dataById.title"
                    class="isLoaded"
                    lazy
                  ></b-image>
                </div>
                <b-modal
                  :active.sync="isImageModalActive"
                  :width="650"
                  scroll="keep"
                >
                  <b-image
                    :src="getGhostImage(dataById.feature_image, 600)"
                    :srcset="
                      getGhostImageSet(dataById.feature_image, [300, 600])
                    "
                    ratio="4by3"
                    :alt="dataById.title"
                    class="isLoaded"
                    lazy
                  ></b-image>
                </b-modal>
                <div class="inside-text" v-html="dataById.html"></div>
                <div class="sharing-bottom">
                  <div class="sharing-article">
                    <div class="show-business">
                      <p
                        class=""
                        v-for="(category, index) in dataById.tags"
                        :key="index"
                      >
                        <a :href="linkName(category.slug)">{{
                          checkName(category.slug)
                        }}</a>
                      </p>
                    </div>
                    <div class="name-at">
                      {{dataById.authors[0].name}} :
                      <span>{{ dataById.updated_at | timeTh }}</span>
                    </div>
                    <div class="social-share-text">
                      <div class="social-but-icon">
                        <i class="fas fa-share-alt"></i>
                      </div>
                      <div class="social-but-text">Share</div>
                    </div>
                    <div class="share-facebook">
                      <ShareNetwork
                        :network="'facebook'"
                        :key="'facebook'"
                        :url="baseUrl + $route.path"
                        :title="
                          typeof dataById.title != 'undefined'
                            ? dataById.title
                            : ''
                        "
                        :description="dataById.description"
                        :quote="
                          typeof dataById.title != 'undefined'
                            ? dataById.title
                            : ''
                        "
                      >
                        <i class="fab fa-facebook-f"></i>
                      </ShareNetwork>
                    </div>
                    <!-- <div class="social-share-btn twitter">
                  <a href>
                    <div class="social-but-icon">
                      <i class="fab fa-twitter"></i>
                    </div>
                  </a>
                </div> -->
                  </div>
                </div>
                <div class="previous-article">
                  <div class="box-related">
                    <h4 class="related-title">
                      <span class="simple-item">RELATED ARTICLES</span>
                    </h4>
                    <div class="columns set-flex">
                      <div
                        class="column is-one-third custom-title hide-mb"
                        v-for="data_news in randomBlog(blogByCategory)"
                        :key="data_news.id"
                      >
                        <CardNews
                          :news="data_news"
                          :routeName="data_news.tags[0].slug"
                        />
                      </div>
                      <div
                        class="column is-one-third custom-title hide-desktop"
                        v-for="(data_news, index) in randomBlog(blogByCategory)"
                        :key="index"
                      >
                        <NewsMiniSite :news="data_news" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-two-thirds" v-else>
          <div class="group-word text-center">
            <h1 class="oops">Oops!</h1>
            <h1 class="key-word">404 - ไม่พบหน้าดังกล่าว</h1>
            <p>หน้านี้อาจถูกลบ หรือ ถูกย้ายไปแล้ว หรือ ปรับปรุงชั่วคราว</p>
            <br />
            <p>The page you are looking for might have been removed had its</p>
            <p>name changed or is temporarily unavailable.</p>
            <a href="/" class="btn-back">กลับหน้าหลัก</a>
          </div>
        </div>
        <div class="column" style="padding-bottom: 30px">
          <div class="column is-full">
            <div
              class="box-social"
              v-for="(data_social, index) in socialList"
              :key="index"
            >
              <Social :social="data_social" />
            </div>
          </div>
          <div class="box-popular">
            <div class="column is-full" v-if="loading == false">
              <HomePopular :blogPopular="blogPopular" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="article-box">
      <span class="set-icon" @click="close()">
        <i class="fas fa-times"></i>
      </span>
      <p class="articles-box-title">บทความที่น่าสนใจ</p>
      <div
        class="columns set-flex is-0"
        v-for="(data_news, index) in Interesting"
        :key="index"
      >
        <NewsMiniSite :news="data_news" />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scope>
.share-facebook {
  display: inline-block;
  position: relative;
  margin-right: 5px;
  a {
    cursor: pointer;
    background: #516eab;
    border-radius: 3px;
    font-size: 16px;
    color: #fff;
    padding: 8px 16px;

    &:hover {
      opacity: 0.85;
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 888px) {
  .is-two-thirds {
    flex: 1 !important;
  }
}
.key-word {
  font-size: 24px;
  font-weight: 500;
}
.name-at {
  color: #afafaf;
  padding-bottom: 10px;
  span {
    padding-left: 15px;
  }
}
.oops {
  font-family: Kanit, sans-serif;
  font-size: 110px;
  margin: 0;
  font-weight: 900;
  background: url(../assets/bgText.png) no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: 50%;
}
.show-business {
  display: flex;
  min-height: 25px;
  overflow-y: auto;
  margin-bottom: 5px;

  a {
    padding: 3px 7px;
    line-height: 13px;
    font-weight: 500;
    font-size: 10px;
    text-transform: uppercase;
    pointer-events: auto;
    color: #fff;
    background-color: #0f4c82;
    cursor: pointer;
  }

  p {
    margin-right: 5px;
  }
}
.btn-back {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 16px;
  background-color: #0f4c82;
  color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
<script>
$(document).ready(function () {
  setTimeout(() => {
    $("a").each(function () {
      if (this.href.indexOf(window.location.host) == -1) {
        $(this).attr("target", "_blank");
        $(this).attr("rel", "noopener");
      }
    });
  }, 500);
});
document.addEventListener("click", function (e) {
  if (e.target.tagName == "a" && !e.target.hasAttribute("target")) {
    e.target.setAttribute("target", "_blank");
  }
});
import CardNews from "@/components/Card";
import Social from "@/components/Social";
import NewsMiniSite from "@/components/NewsMiniSite";
import HomePopular from "@/components/HomePopular";
import { BLOG } from "@/service/axios.js";
export default {
  async mounted() {
    this.getBlogPopular();
    await this.getBlogById();
  },
  computed: {
    socialList() {
      return this.$store.state.socialList;
    },
  },
  data() {
    return {
      isImageModalActive: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      blogId: "",
      dataById: [],
      blogPopular: [],
      blogByCategory: [],
      Interesting: [],
      categoryNow: "",
      loading1: true,
      loading: true,
      errorBlogId: true,
      nameSlug: "",
    };
  },
  methods: {
    checkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return str[1];
      } else {
        return str[0];
      }
    },
    linkName(name) {
      var str = name.split("_");
      if (str.length == 2) {
        return "/category/" + str[0] + "/" + str[1];
      } else {
        return "/category/" + str[0];
      }
    },
    close() {
      var article = $(".article-box");
      article.hide();
    },
    getBlogPopular() {
      BLOG.get(
        `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=5b&filter=featured:true&include=tags`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.blogPopular = res.data.posts;

            this.loading = false;
            console.log("blogPopular", this.blogPopular);
            var getAll = this.blogPopular;
            this.shuffle(getAll);
            this.Interesting = getAll.slice(1, 3);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
    shuffle(array) {
      var currentIndex = array.length,
        temporaryValue,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    },
    randomBlog(arr = []) {
      console.log(arr, "arr");
      let cut = arr.findIndex((a) => a.slug == this.$route.params.slug);
      if (cut > -1) {
        arr = arr.splice(cut, 1);
      }
      if (arr.length > 3) {
        arr = arr.slice(0, 3);
      }
      return arr;
    },
    getBlogActicles() {
      console.log(this.categoryNow);
      if (this.categoryNow != "") {
        BLOG.get(
          `posts?key=db9af1ab9fa83c98a75d2f98bb&page=1&limit=3b&filter=tag:${this.categoryNow}&include=tags`
        )
          .then(async (res) => {
            if (res.status == 200) {
              this.blogByCategory = res.data.posts;
              var getAll = this.blogPopular;
              this.shuffle(getAll);
              this.blogByCategory = getAll;
              console.log(this.blogByCategory);
            } else {
              this.alertFailError();
            }
          })
          .catch((e) => {
            this.alertCatchError(e);
            console.log("get blog error");
          });
      }
    },
    getBlogById() {
      BLOG.get(
        `posts/slug/${this.$route.params.slug}/?key=db9af1ab9fa83c98a75d2f98bb&include=tags,authors`
      )
        .then(async (res) => {
          if (res.status == 200) {
            this.dataById = res.data.posts[0];
            var split = this.dataById.tags[0].slug.split("_");
            this.categoryNow = split[0];

            this.loading1 = false;
            this.getBlogActicles();
            console.log(this.dataById);
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          this.errorBlogId = false;
          this.alertCatchError(e);
          console.log("get blog error");
        });
    },
  },
  components: {
    CardNews,
    Social,
    NewsMiniSite,
    HomePopular,
  },
};
</script>